import React, {Component} from 'react';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';
import Button from '../../../components/Button';

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

class LoginForm extends Component {
  state = {
    email: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.login(this.state);
  };

  render() {
    const {email, password} = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <FormWrapper>
          <TextField
            label="Email" // className={classes.textField}
            value={email}
            onChange={this.handleChange('email')}
            required
          />
          <TextField
            label="Password" // className={classes.textField}
            type="password"
            value={password}
            onChange={this.handleChange('password')}
            required
          />
          <Button style={{justifySelf: 'flex-end'}}>Sign In</Button>
        </FormWrapper>
      </form>
    );
  }
}

export default LoginForm;
