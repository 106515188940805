import {oldYork, newYork} from '../api';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHANGE_PAGE,
  SET_PAYMENT_METHODS,
  GET_GROUP_ORDERS_SUCCESS,
  GET_GROUP_ORDER_DETAILS_REQUEST,
  GET_GROUP_ORDER_DETAILS_SUCCESS,
  CLEAR_GROUP_ORDER_DETAILS,
  SHOW_CANCEL_GROUP_ORDER_FORM,
  HIDE_CANCEL_GROUP_ORDER_FORM,
  CANCEL_GROUP_ORDER_REQUEST,
  CANCEL_GROUP_ORDER_SUCCESS,
  CANCEL_GROUP_ORDER_FAILURE,
  SHOW_CANCEL_SINGLE_ORDER_FORM,
  HIDE_CANCEL_SINGLE_ORDER_FORM,
  CANCEL_SINGLE_ORDER_REQUEST,
  CANCEL_SINGLE_ORDER_SUCCESS,
  CANCEL_SINGLE_ORDER_FAILURE,
  GET_DELIVERY_ADDRESSES_REQUEST,
  GET_DELIVERY_ADDRESSES_SUCCESS,
  GET_DELIVERY_ADDRESSES_FAILURE,
  SHOW_CREATE_GROUP_ORDER_FORM,
  HIDE_CREATE_GROUP_ORDER_FORM,
  CREATE_GROUP_ORDER_REQUEST,
  CREATE_GROUP_ORDER_SUCCESS,
  CREATE_GROUP_ORDER_FAILURE,
  SHOW_EDIT_GROUP_ORDER_FORM,
  HIDE_EDIT_GROUP_ORDER_FORM,
  EDIT_GROUP_ORDER_REQUEST,
  EDIT_GROUP_ORDER_SUCCESS,
  EDIT_GROUP_ORDER_FAILURE,
  SHOW_CREATE_PAYMENT_METHOD_FORM,
  HIDE_CREATE_PAYMENT_METHOD_FORM,
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  SHOW_CREATE_DELIVERY_ADDRESS_FORM,
  HIDE_CREATE_DELIVERY_ADDRESS_FORM,
  CREATE_DELIVERY_ADDRESS_REQUEST,
  CREATE_DELIVERY_ADDRESS_SUCCESS,
  CREATE_DELIVERY_ADDRESS_FAILURE,
  CREATE_ALERT,
  CLEAR_ALERT,
  EXPORT_GROUP_ORDER_REQUEST,
  EXPORT_GROUP_ORDER_SUCCESS,
  EXPORT_GROUP_ORDER_FAILURE
} from './types';

export function login(credentials) {
  return function(dispatch) {
    dispatch({type: LOGIN_REQUEST});
    return oldYork
      .post(`/v2_login`, {
        ...credentials,
        latitude: 1,
        longitude: 2,
        source: 'cf'
      })
      .then(
        response => {
          if (response.data.result == 'true') {
            dispatch({type: LOGIN_SUCCESS});
            localStorage.setItem('cfgomtoken', response.data.session_id);
            localStorage.setItem(
              'cfgompaymentMethods',
              JSON.stringify(response.data.account_info.cards)
            );
            dispatch(setPaymentMethods(response.data.account_info.cards));
            dispatch(changePage('home'));
          } else {
            dispatch({type: LOGIN_FAILURE});
            dispatch(
              createAlert({
                type: 'error',
                message: response.data.error
              })
            );
          }
        },
        error => {
          dispatch({type: LOGIN_FAILURE});
        }
      );
  };
}

export function setPaymentMethods(paymentMethods) {
  return {
    type: SET_PAYMENT_METHODS,
    paymentMethods
  };
}

export function logout() {
  return function(dispatch) {
    dispatch(changePage('login'));
    localStorage.removeItem('cfgomtoken');
    localStorage.removeItem('cfgompaymentMethods');
  };
}

export function changePage(pageToShow) {
  return {
    type: CHANGE_PAGE,
    pageToShow
  };
}

export function getGroupOrders() {
  return function(dispatch) {
    return newYork.get(`/cforder/groups`).then(
      response => {
        dispatch({
          type: GET_GROUP_ORDERS_SUCCESS,
          groupOrders: response.data.data
        });
      },
      error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to fetch group orders.'
          })
        );
      }
    );
  };
}

export function getGroupOrderDetails(groupOrderId) {
  return function(dispatch) {
    dispatch(changePage('details'));
    dispatch({type: GET_GROUP_ORDER_DETAILS_REQUEST});
    return newYork.get(`/cforder/groups/${groupOrderId}?include=orders`).then(
      response => {
        dispatch({
          type: GET_GROUP_ORDER_DETAILS_SUCCESS,
          groupOrderDetails: response.data.data
        });
      },
      error => {
        dispatch(
          createAlert({
            type: 'error',
            message: 'An error occurred trying to fetch group order.'
          })
        );
      }
    );
  };
}

export function clearGroupOrderDetails() {
  return {
    type: CLEAR_GROUP_ORDER_DETAILS
  };
}

export function showCancelGroupOrderForm(groupOrderIdToCancel) {
  return {
    type: SHOW_CANCEL_GROUP_ORDER_FORM,
    groupOrderIdToCancel
  };
}

export function hideCancelGroupOrderForm() {
  return {
    type: HIDE_CANCEL_GROUP_ORDER_FORM
  };
}

export function cancelGroupOrder(groupOrderId) {
  return function(dispatch) {
    dispatch({type: CANCEL_GROUP_ORDER_REQUEST});
    return newYork.delete(`/cforder/groups/${groupOrderId}`).then(
      response => {
        dispatch({type: CANCEL_GROUP_ORDER_SUCCESS});
        dispatch(
          createAlert({
            type: 'success',
            message: 'Group Order successfully cancelled.'
          })
        );
      },
      error => {
        dispatch({type: CANCEL_GROUP_ORDER_FAILURE});
        dispatch(
          createAlert({
            type: 'error',
            message: `${
              error.response.data.errors.message
                ? error.response.data.errors.message
                : 'An error occurred trying to cancel group order.'
            }`
          })
        );
      }
    );
  };
}

export function showCancelSingleOrderForm(singleOrderIdToCancel) {
  return {
    type: SHOW_CANCEL_SINGLE_ORDER_FORM,
    singleOrderIdToCancel
  };
}

export function hideCancelSingleOrderForm() {
  return {
    type: HIDE_CANCEL_SINGLE_ORDER_FORM
  };
}

export function cancelSingleOrder({groupOrderId, singleOrderIdToCancel}) {
  return function(dispatch) {
    dispatch({type: CANCEL_SINGLE_ORDER_REQUEST});
    return newYork
      .post(`/cforder/groups/${groupOrderId}/cancel/${singleOrderIdToCancel}`)
      .then(
        response => {
          dispatch({
            type: CANCEL_SINGLE_ORDER_SUCCESS,
            groupOrderId,
            singleOrderId: singleOrderIdToCancel
          });
          dispatch(
            createAlert({
              type: 'success',
              message: 'Order successfully cancelled.'
            })
          );
        },
        error => {
          dispatch({type: CANCEL_SINGLE_ORDER_FAILURE});
          dispatch(
            createAlert({
              type: 'error',
              message: 'An error occurred trying to cancel order.'
            })
          );
        }
      );
  };
}

export function getDeliveryAddresses() {
  return function(dispatch) {
    dispatch({type: GET_DELIVERY_ADDRESSES_REQUEST});
    return newYork.get('/cfcustomer/addresses').then(
      response => {
        dispatch({
          type: GET_DELIVERY_ADDRESSES_SUCCESS,
          deliveryAddresses: response.data.data
        });
      },
      error => {
        dispatch({
          type: GET_DELIVERY_ADDRESSES_FAILURE
        });
      }
    );
  };
}

export function showCreateGroupOrderForm() {
  return {
    type: SHOW_CREATE_GROUP_ORDER_FORM
  };
}

export function hideCreateGroupOrderForm() {
  return {
    type: HIDE_CREATE_GROUP_ORDER_FORM
  };
}

export function createGroupOrder(formValues) {
  return function(dispatch) {
    dispatch({type: CREATE_GROUP_ORDER_REQUEST});
    return newYork.post('/cforder/group', formValues).then(
      response => {
        dispatch({
          type: CREATE_GROUP_ORDER_SUCCESS
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Group Order successfully created.'
          })
        );
      },
      error => {
        dispatch({type: CREATE_GROUP_ORDER_FAILURE});
        dispatch(
          createAlert({
            type: 'error',
            message: error.response.data.errors.message
          })
        );
      }
    );
  };
}

export function showEditGroupOrderForm(params) {
  return {
    type: SHOW_EDIT_GROUP_ORDER_FORM,
    params
  };
}

export function hideEditGroupOrderForm() {
  return {
    type: HIDE_EDIT_GROUP_ORDER_FORM
  };
}

export function editGroupOrder({groupOrderId, formValues}) {
  return function(dispatch) {
    dispatch({type: EDIT_GROUP_ORDER_REQUEST});
    return newYork.patch(`/cforder/groups/${groupOrderId}`, formValues).then(
      response => {
        dispatch({
          type: EDIT_GROUP_ORDER_SUCCESS
        });
        dispatch(getGroupOrderDetails(groupOrderId));
        dispatch(getGroupOrders());
        dispatch(
          createAlert({
            type: 'success',
            message: 'Group Order successfully updated.'
          })
        );
      },
      error => {
        dispatch({type: EDIT_GROUP_ORDER_FAILURE});
        dispatch(
          createAlert({
            type: 'error',
            message: error.response.data.errors.message
          })
        );
      }
    );
  };
}

export function showCreatePaymentMethodForm() {
  return {
    type: SHOW_CREATE_PAYMENT_METHOD_FORM
  };
}

export function hideCreatePaymentMethodForm() {
  return {
    type: HIDE_CREATE_PAYMENT_METHOD_FORM
  };
}

export function createPaymentMethod(formValues) {
  return function(dispatch) {
    dispatch({type: CREATE_PAYMENT_METHOD_REQUEST});
    return newYork.post('/payment-methods', formValues).then(
      response => {
        dispatch({
          type: CREATE_PAYMENT_METHOD_SUCCESS,
          paymentMethods: response.data.data
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Payment Method successfully created.'
          })
        );
      },
      error => {
        dispatch({type: CREATE_PAYMENT_METHOD_FAILURE});
        dispatch(
          createAlert({
            type: 'error',
            message: `${
              error.response.data.errors.message
                ? error.response.data.errors.message
                : 'An error occurred trying to create Payment Method.'
            }`
          })
        );
      }
    );
  };
}

export function showCreateDeliveryAddressForm() {
  return {
    type: SHOW_CREATE_DELIVERY_ADDRESS_FORM
  };
}

export function hideCreateDeliveryAddressForm() {
  return {
    type: HIDE_CREATE_DELIVERY_ADDRESS_FORM
  };
}

export function createDeliveryAddress(formValues) {
  return function(dispatch) {
    dispatch({type: CREATE_DELIVERY_ADDRESS_REQUEST});
    return newYork.post('/customer/addresses', formValues).then(
      response => {
        dispatch({
          type: CREATE_DELIVERY_ADDRESS_SUCCESS,
          deliveryAddress: {
            id: response.data.data.customer_address_id,
            ...formValues
          }
        });
        dispatch(
          createAlert({
            type: 'success',
            message: 'Delivery Address successfully created.'
          })
        );
      },
      error => {
        dispatch({type: CREATE_DELIVERY_ADDRESS_FAILURE});
        dispatch(
          createAlert({
            type: 'error',
            message: `${
              error.response.data.errors.message
                ? error.response.data.errors.message
                : 'An error occurred trying to create Delivery Address.'
            }`
          })
        );
      }
    );
  };
}

export function createAlert(alert) {
  return {
    type: CREATE_ALERT,
    alert
  };
}

export function clearAlert() {
  return {
    type: CLEAR_ALERT
  };
}

export function exportGroupOrder(groupOrderId) {
  const fileName = `group-order-details-${groupOrderId}`;

  const downloadAsCSV = (function() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    return function(data, fileName) {
      const blob = new Blob([data], {type: 'octet/stream'}),
        url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    };
  })();

  return function(dispatch) {
    dispatch({
      type: EXPORT_GROUP_ORDER_REQUEST,
      groupOrderIdRequestingExport: groupOrderId
    });
    return newYork
      .get(`/cforder/groups/${groupOrderId}?include=orders&export=true`)
      .then(
        response => {
          dispatch({
            type: EXPORT_GROUP_ORDER_SUCCESS
          });
          downloadAsCSV(response.data, `${fileName}.csv`);
        },
        error => {
          dispatch(
            createAlert({
              type: 'error',
              message: 'An error occurred trying to export group order.'
            })
          );
          dispatch({
            type: EXPORT_GROUP_ORDER_FAILURE
          });
        }
      );
  };
}
