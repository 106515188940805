import React from 'react';
import styled from 'styled-components';
import PageTitle from './PageTitle';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 48px;
  @media (max-width: 600px) {
    grid-gap: 32px;
  }
`;

const PageWrapper = ({pageTitle, children}) => {
  return (
    <Wrapper>
      <PageTitle>{pageTitle}</PageTitle>
      <div>{children}</div>
    </Wrapper>
  );
};

export default PageWrapper;
