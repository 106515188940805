import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import styled from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Button, {ButtonRow} from '../../components/Button';
import FlatButton from '../../components/FlatButton';
import DetailsCard from './components/DetailsCard';
import Title from '../../components/Title';
import EditGroupOrder from './components/EditGroupOrder';
import Card from '../../components/Card';
import SingleOrdersTable from './components/SingleOrdersTable';
import CancelSingleOrderConfirmation from './components/CancelSingleOrderConfirmation';

const BackButton = styled(FlatButton)`
  position: absolute;
  top: -20px;
  text-decoration: underline;
  @media (max-width: 600px) {
    margin-left: 16px;
  }
`;

class Details extends Component {
  renderEditButtons = () => (
    <ButtonRow justifyContent="flex-end" style={{marginBottom: '24px'}}>
      <Button
        onClick={() =>
          this.props.showEditGroupOrderForm({
            groupOrderId: this.props.groupOrderDetails.id,
            type: 'groupSize',
            groupSize: this.props.groupOrderDetails.total_group_size
          })
        }
        type="button"
        small
      >
        Edit Group Size
      </Button>
      <Button
        onClick={() =>
          this.props.showEditGroupOrderForm({
            groupOrderId: this.props.groupOrderDetails.id,
            type: 'dateTime',
            delivery_date: this.props.groupOrderDetails.delivery_date
          })
        }
        type="button"
        small
      >
        Edit Delivery Date/Time
      </Button>
    </ButtonRow>
  );

  handleBackToHome = () => {
    this.props.changePage('home');
    this.props.clearGroupOrderDetails();
  };

  render() {
    const {groupOrderDetails} = this.props;
    return (
      <div style={{position: 'relative'}}>
        <BackButton onClick={this.handleBackToHome}>Group Orders /</BackButton>
        <PageWrapper pageTitle="Details" />
        {groupOrderDetails &&
          groupOrderDetails.status === 'pending' &&
          this.renderEditButtons()}
        {groupOrderDetails && (
          <div style={{display: 'grid', gridGap: '20px'}}>
            <DetailsCard groupOrderDetails={groupOrderDetails} />
            <Card>
              <SingleOrdersTable
                isPending={groupOrderDetails.status === 'pending'}
                singleOrders={groupOrderDetails.orders}
              />
            </Card>
          </div>
        )}
        <EditGroupOrder />
        <CancelSingleOrderConfirmation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupOrderDetails: state.groupOrderDetails
  };
}

export default connect(
  mapStateToProps,
  actions
)(Details);
