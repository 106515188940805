import React, {Component} from 'react';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';

const Wrapper = styled.div`
  width: 500px;
  padding: 20px;
  background-color: white;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

class ModalComponent extends Component {
  render() {
    const {closeModal, open, children, fullScreen} = this.props;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={closeModal}
        disableBackdropClick={true}
        aria-labelledby="responsive-dialog-title"
      >
        <Wrapper>{children}</Wrapper>
      </Dialog>
    );
  }
}

export default withMobileDialog({breakpoint: 'xs'})(ModalComponent);
