import styled from 'styled-components';

const Card = styled.div`
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.25);
  background-color: white;
  padding: 32px;
  @media (max-width: 600px) {
    padding: 24px;
  }
`;

export default Card;
