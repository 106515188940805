import styled from 'styled-components';

const FlatButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

export default FlatButton;
