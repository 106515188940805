import React, {Component} from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import TextField from '@material-ui/core/TextField';
import Button, {ButtonRow} from '../../../components/Button';

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

class CreateDeliveryAddress extends Component {
  state = {
    line1: '',
    line2: '',
    line3: '',
    state: 'ON',
    country: 'CA',
    details: '',
    is_preferred: true
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.createDeliveryAddress(this.state);
  };

  render() {
    const {line1, line2, city, state, zip, type, details} = this.state;
    const {
      createDeliveryAddressFormIsVisible,
      hideCreateDeliveryAddressForm
    } = this.props;
    return (
      <Modal
        closeModal={hideCreateDeliveryAddressForm}
        open={createDeliveryAddressFormIsVisible}
      >
        <Title style={{marginBottom: '20px'}}>Add Delivery Address</Title>
        <form onSubmit={this.handleSubmit}>
          <FormWrapper>
            <TextField
              label="Address Line 1"
              value={line1}
              onChange={this.handleChange('line1')}
              required
            />
            <TextField
              label="Address Line 2"
              value={line2}
              onChange={this.handleChange('line2')}
            />
            <TextField
              label="City"
              value={city}
              onChange={this.handleChange('city')}
              required
            />
            <TextField
              label="Province"
              value={state}
              onChange={this.handleChange('state')}
              disabled
              required
            />
            <TextField
              label="Postal Code"
              value={zip}
              onChange={this.handleChange('zip')}
              required
            />
            <TextField
              label="Residence Type"
              value={type}
              onChange={this.handleChange('type')}
              required
            />
            <TextField
              label="Details"
              value={details}
              onChange={this.handleChange('details')}
            />
            <ButtonRow justifyContent="space-between ">
              <Button
                onClick={hideCreateDeliveryAddressForm}
                type="button"
                secondary
              >
                Cancel
              </Button>
              <Button>Add Delivery Address</Button>
            </ButtonRow>
          </FormWrapper>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    createDeliveryAddressFormIsVisible:
      state.createDeliveryAddressFormIsVisible,
    requestingCreateDeliveryAddress: state.requestingCreateDeliveryAddress
  };
}

export default connect(
  mapStateToProps,
  actions
)(CreateDeliveryAddress);
