import React, {Component} from 'react';
import styled from 'styled-components';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import Card from '../../../components/Card';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const DetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 250px);
  grid-gap: 24px;
  align-items: flex-start;
  justify-content: space-between;
`;

const DetailAndLabel = styled.div`
  display: grid;
  grid-gap: 4px;
  /* text-align: center; */
`;

const DetailLabel = styled.div`
  font-size: 12px;
`;

const Detail = styled.div`
  font-weight: bold;
  font-size: 18px;
  text-transform: capitalize;
`;

class DetailsCard extends Component {
  renderDeliveryAddress = () => {
    const {line1, line2, city, state} = this.props.groupOrderDetails;
    return (
      <Detail>
        <div>
          {line2 && `${line2} - `}
          {line1}
        </div>
        <div>
          {city}, {state}
        </div>
      </Detail>
    );
  };

  renderTotalPrice = () => {
    let totalPrice = 0;
    const {groupOrderDetails} = this.props;
    if (groupOrderDetails.orders && groupOrderDetails.orders.length) {
      groupOrderDetails.orders.forEach(order => (totalPrice += order.total));
    }
    return totalPrice;
  };

  render() {
    const {groupOrderDetails} = this.props;
    const {
      order_group_code,
      status,
      current_group_size,
      total_group_size,
      delivery_date,
      date_limit,
      time_limit,
      card_type,
      last_four
    } = groupOrderDetails;
    return (
      <Card>
        <Wrapper>
          <DetailsWrapper>
            <DetailAndLabel>
              <DetailLabel>Group Order Code</DetailLabel>
              <Detail>{order_group_code}</Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Status</DetailLabel>
              <Detail>{status}</Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Participants</DetailLabel>
              <Detail>
                {current_group_size} / {total_group_size}
              </Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Delivery Date/Time</DetailLabel>
              <Detail>{moment(delivery_date).format('MMM DD h:mm A')}</Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Deadline Time</DetailLabel>
              <Detail>
                {moment(`${date_limit} ${time_limit}`).format('h:mm A')}
              </Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Delivery Address</DetailLabel>
              {this.renderDeliveryAddress()}
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Payment Method</DetailLabel>
              <Detail>
                {card_type.toUpperCase()} **** {last_four}
              </Detail>
            </DetailAndLabel>
            <DetailAndLabel>
              <DetailLabel>Total Price</DetailLabel>
              <Detail>
                <CurrencyFormat
                  prefix="$"
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={this.renderTotalPrice()}
                  displayType={'text'}
                />
              </Detail>
            </DetailAndLabel>
          </DetailsWrapper>
        </Wrapper>
      </Card>
    );
  }
}

export default DetailsCard;
