export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';

export const CHANGE_PAGE = 'CHANGE_PAGE';

export const GET_GROUP_ORDERS_SUCCESS = 'GET_GROUP_ORDERS_SUCCESS';

export const GET_GROUP_ORDER_DETAILS_REQUEST =
  'GET_GROUP_ORDER_DETAILS_REQUEST';
export const GET_GROUP_ORDER_DETAILS_SUCCESS =
  'GET_GROUP_ORDER_DETAILS_SUCCESS';

export const CLEAR_GROUP_ORDER_DETAILS = 'CLEAR_GROUP_ORDER_DETAILS';

export const SHOW_CANCEL_GROUP_ORDER_FORM = 'SHOW_CANCEL_GROUP_ORDER_FORM';
export const HIDE_CANCEL_GROUP_ORDER_FORM = 'HIDE_CANCEL_GROUP_ORDER_FORM';

export const CANCEL_GROUP_ORDER_REQUEST = 'CANCEL_GROUP_ORDER_REQUEST';
export const CANCEL_GROUP_ORDER_SUCCESS = 'CANCEL_GROUP_ORDER_SUCCESS';
export const CANCEL_GROUP_ORDER_FAILURE = 'CANCEL_GROUP_ORDER_FAILURE';

export const SHOW_CANCEL_SINGLE_ORDER_FORM = 'SHOW_CANCEL_SINGLE_ORDER_FORM';
export const HIDE_CANCEL_SINGLE_ORDER_FORM = 'HIDE_CANCEL_SINGLE_ORDER_FORM';

export const CANCEL_SINGLE_ORDER_REQUEST = 'CANCEL_SINGLE_ORDER_REQUEST';
export const CANCEL_SINGLE_ORDER_SUCCESS = 'CANCEL_SINGLE_ORDER_SUCCESS';
export const CANCEL_SINGLE_ORDER_FAILURE = 'CANCEL_SINGLE_ORDER_FAILURE';

export const GET_DELIVERY_ADDRESSES_REQUEST = 'GET_DELIVERY_ADDRESSES_REQUEST';
export const GET_DELIVERY_ADDRESSES_SUCCESS = 'GET_DELIVERY_ADDRESSES_SUCCESS';
export const GET_DELIVERY_ADDRESSES_FAILURE = 'GET_DELIVERY_ADDRESSES_FAILURE';

export const SHOW_CREATE_GROUP_ORDER_FORM = 'SHOW_CREATE_GROUP_ORDER_FORM';
export const HIDE_CREATE_GROUP_ORDER_FORM = 'HIDE_CREATE_GROUP_ORDER_FORM';

export const CREATE_GROUP_ORDER_REQUEST = 'CREATE_GROUP_ORDER_REQUEST';
export const CREATE_GROUP_ORDER_SUCCESS = 'CREATE_GROUP_ORDER_SUCCESS';
export const CREATE_GROUP_ORDER_FAILURE = 'CREATE_GROUP_ORDER_FAILURE';

export const SHOW_EDIT_GROUP_ORDER_FORM = 'SHOW_EDIT_GROUP_ORDER_FORM';
export const HIDE_EDIT_GROUP_ORDER_FORM = 'HIDE_EDIT_GROUP_ORDER_FORM';

export const EDIT_GROUP_ORDER_REQUEST = 'EDIT_GROUP_ORDER_REQUEST';
export const EDIT_GROUP_ORDER_SUCCESS = 'EDIT_GROUP_ORDER_SUCCESS';
export const EDIT_GROUP_ORDER_FAILURE = 'EDIT_GROUP_ORDER_FAILURE';

export const SHOW_CREATE_PAYMENT_METHOD_FORM =
  'SHOW_CREATE_PAYMENT_METHOD_FORM';
export const HIDE_CREATE_PAYMENT_METHOD_FORM =
  'HIDE_CREATE_PAYMENT_METHOD_FORM';

export const CREATE_PAYMENT_METHOD_REQUEST = 'CREATE_PAYMENT_METHOD_REQUEST';
export const CREATE_PAYMENT_METHOD_SUCCESS = 'CREATE_PAYMENT_METHOD_SUCCESS';
export const CREATE_PAYMENT_METHOD_FAILURE = 'CREATE_PAYMENT_METHOD_FAILURE';

export const SHOW_CREATE_DELIVERY_ADDRESS_FORM =
  'SHOW_CREATE_DELIVERY_ADDRESS_FORM';
export const HIDE_CREATE_DELIVERY_ADDRESS_FORM =
  'HIDE_CREATE_DELIVERY_ADDRESS_FORM';

export const CREATE_DELIVERY_ADDRESS_REQUEST =
  'CREATE_DELIVERY_ADDRESS_REQUEST';
export const CREATE_DELIVERY_ADDRESS_SUCCESS =
  'CREATE_DELIVERY_ADDRESS_SUCCESS';
export const CREATE_DELIVERY_ADDRESS_FAILURE =
  'CREATE_DELIVERY_ADDRESS_FAILURE';

export const CREATE_ALERT = 'CREATE_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const EXPORT_GROUP_ORDER_REQUEST = 'EXPORT_GROUP_ORDER_REQUEST';
export const EXPORT_GROUP_ORDER_SUCCESS = 'EXPORT_GROUP_ORDER_SUCCESS';
export const EXPORT_GROUP_ORDER_FAILURE = 'EXPORT_GROUP_ORDER_FAILURE';
