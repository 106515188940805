import React, {Component} from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button, {ButtonRow} from '../../../components/Button';

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

class CreatePaymentMethod extends Component {
  state = {};

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const formValues = {
      credit_card: {
        ...this.state,
        expiry_date: `20${this.state.expiry_date.substring(
          2
        )}-${this.state.expiry_date.substring(0, 2)}-01`
      },
      type: 'credit_card'
    };
    this.props.createPaymentMethod(formValues);
  };

  render() {
    const {cardholder_name, card_number, expiry_date, cvv} = this.state;
    const {
      createPaymentMethodFormIsVisible,
      hideCreatePaymentMethodForm
    } = this.props;
    return (
      <Modal
        closeModal={hideCreatePaymentMethodForm}
        open={createPaymentMethodFormIsVisible}
      >
        <Title style={{marginBottom: '20px'}}>Add Payment Method</Title>
        <form onSubmit={this.handleSubmit}>
          <FormWrapper>
            <TextField
              label="Cardholder Name"
              value={cardholder_name}
              // inputProps={{min: todayDateString}}
              onChange={this.handleChange('cardholder_name')}
              required
            />
            <TextField
              label="Card Number"
              value={card_number}
              type="number"
              inputProps={{
                pattern: '[0-9.]+'
              }}
              onChange={this.handleChange('card_number')}
              required
            />
            <TextField
              label="Expiry Date"
              type="number"
              value={expiry_date}
              onChange={this.handleChange('expiry_date')}
              required
            />
            <TextField
              label="CVV"
              type="number"
              value={cvv}
              onChange={this.handleChange('cvv')}
              required
            />
            <ButtonRow justifyContent="space-between ">
              <Button
                onClick={hideCreatePaymentMethodForm}
                type="button"
                secondary
              >
                Cancel
              </Button>
              <Button>Add Payment Method</Button>
            </ButtonRow>
          </FormWrapper>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    createPaymentMethodFormIsVisible: state.createPaymentMethodFormIsVisible,
    requestingCreatePaymentMethod: state.requestingCreatePaymentMethod
  };
}

export default connect(
  mapStateToProps,
  actions
)(CreatePaymentMethod);
