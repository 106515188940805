import React, {Component, Fragment} from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button, {ButtonRow} from '../../../components/Button';

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const todayDateString = moment().format('YYYY-MM-DD');

const initialState = {
  group_size: ''
};

class EditGroupOrder extends Component {
  state = initialState;

  componentDidUpdate(prevProps) {
    if (
      prevProps.editGroupOrderFormIsVisible &&
      !this.props.editGroupOrderFormIsVisible
    ) {
      this.setState(initialState);
    }
    if (
      !prevProps.editGroupOrderFormIsVisible &&
      this.props.editGroupOrderFormIsVisible
    ) {
      if (this.props.editParams.type === 'groupSize') {
        this.setState({group_size: this.props.editParams.groupSize});
      } else {
        this.setState({
          date_limit: this.props.editParams.delivery_date.substring(0, 10),
          time_limit: this.props.editParams.delivery_date.substring(11)
        });
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    let formValues;
    if (this.props.editParams.type === 'groupSize') {
      formValues = {group_size: parseInt(this.state.group_size)};
    } else {
      formValues = {
        date_limit: this.state.date_limit,
        time_limit:
          this.state.time_limit.length === 5
            ? `${this.state.time_limit}:00`
            : this.state.time_limit
      };
    }
    this.props.editGroupOrder({
      groupOrderId: this.props.editParams.groupOrderId,
      formValues
    });
  };

  render() {
    const {date_limit, time_limit, group_size} = this.state;
    const {
      editParams,
      editGroupOrderFormIsVisible,
      hideEditGroupOrderForm
    } = this.props;
    return (
      <Modal
        closeModal={hideEditGroupOrderForm}
        open={editGroupOrderFormIsVisible}
      >
        <Title style={{marginBottom: '20px'}}>Create Group Order</Title>
        {editGroupOrderFormIsVisible && (
          <form onSubmit={this.handleSubmit}>
            <FormWrapper>
              {editParams.type === 'dateTime' && (
                <Fragment>
                  <TextField
                    label="Delivery Date"
                    value={date_limit}
                    type="date"
                    InputLabelProps={{shrink: true}}
                    inputProps={{min: todayDateString}}
                    onChange={this.handleChange('date_limit')}
                    required
                  />
                  <TextField
                    label="Delivery Time"
                    type="time"
                    InputLabelProps={{shrink: true}}
                    inputProps={{
                      min: `${
                        date_limit === todayDateString
                          ? moment()
                              .add(2, 'hours')
                              .format('HH:mm')
                          : '00:00'
                      }`
                    }}
                    value={time_limit}
                    onChange={this.handleChange('time_limit')}
                    required
                  />
                </Fragment>
              )}
              {editParams.type === 'groupSize' && (
                <TextField
                  label="Group Size"
                  type="number"
                  value={group_size}
                  onChange={this.handleChange('group_size')}
                  inputProps={{min: 2}}
                  required
                />
              )}
              <ButtonRow justifyContent="space-between ">
                <Button
                  onClick={hideEditGroupOrderForm}
                  type="button"
                  secondary
                >
                  Cancel
                </Button>
                <Button>
                  {editParams.type === 'groupSize'
                    ? 'Update Group Size'
                    : 'Update Delivery Date/Time'}
                </Button>
              </ButtonRow>
            </FormWrapper>
          </form>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    editGroupOrderFormIsVisible: state.editGroupOrderFormIsVisible,
    editParams: state.editParams
  };
}

export default connect(
  mapStateToProps,
  actions
)(EditGroupOrder);
