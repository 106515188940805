import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '../../../components/Button';
import CurrencyFormat from 'react-currency-format';

const SingleOrdersTable = ({
  singleOrders,
  showCancelSingleOrderForm,
  isPending
}) => {
  const renderProducts = products => {
    return (
      products &&
      products.length &&
      products.map(product => (
        <div key={product.product_id}>• {product.name}</div>
      ))
    );
  };

  const renderNoOrdersMessage = () => (
    <div style={{fontSize: '12px'}}>
      There are no individual orders to display.
    </div>
  );

  return (
    <div style={{overflowX: 'auto'}}>
      {singleOrders.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Products</TableCell>
              <TableCell align="right">Price ($)</TableCell>
              {isPending && <TableCell align="center">Actions</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {singleOrders.map(singleOrder => {
              return (
                <TableRow key={singleOrder.order_id}>
                  <TableCell component="th" scope="row">
                    {singleOrder.special_instruction}
                  </TableCell>
                  <TableCell>
                    <div style={{display: 'grid', gridGap: '6px'}}>
                      {renderProducts(singleOrder.config_products)}
                    </div>
                  </TableCell>
                  <TableCell align="right">
                    <CurrencyFormat
                      decimalScale={2}
                      fixedDecimalScale={true}
                      value={singleOrder.total}
                      displayType={'text'}
                    />
                  </TableCell>
                  {isPending && (
                    <TableCell align="center">
                      {singleOrder.config_products &&
                        singleOrder.config_products.length && (
                          <Button
                            onClick={() =>
                              showCancelSingleOrderForm(singleOrder.order_id)
                            }
                            small
                          >
                            Cancel
                          </Button>
                        )}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      ) : (
        <Fragment>{renderNoOrdersMessage()}</Fragment>
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    cancelSingleOrderFormIsVisible: state.cancelSingleOrderFormIsVisible,
    singleOrderIdToCancel: state.singleOrderIdToCancel
  };
}

export default connect(
  mapStateToProps,
  actions
)(SingleOrdersTable);
