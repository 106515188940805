import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  SET_PAYMENT_METHODS,
  CHANGE_PAGE,
  GET_GROUP_ORDERS_SUCCESS,
  GET_GROUP_ORDER_DETAILS_REQUEST,
  GET_GROUP_ORDER_DETAILS_SUCCESS,
  CLEAR_GROUP_ORDER_DETAILS,
  SHOW_CANCEL_GROUP_ORDER_FORM,
  HIDE_CANCEL_GROUP_ORDER_FORM,
  CANCEL_GROUP_ORDER_REQUEST,
  CANCEL_GROUP_ORDER_SUCCESS,
  CANCEL_GROUP_ORDER_FAILURE,
  SHOW_CANCEL_SINGLE_ORDER_FORM,
  HIDE_CANCEL_SINGLE_ORDER_FORM,
  CANCEL_SINGLE_ORDER_REQUEST,
  CANCEL_SINGLE_ORDER_SUCCESS,
  CANCEL_SINGLE_ORDER_FAILURE,
  GET_DELIVERY_ADDRESSES_REQUEST,
  GET_DELIVERY_ADDRESSES_SUCCESS,
  GET_DELIVERY_ADDRESSES_FAILURE,
  SHOW_CREATE_GROUP_ORDER_FORM,
  HIDE_CREATE_GROUP_ORDER_FORM,
  CREATE_GROUP_ORDER_REQUEST,
  CREATE_GROUP_ORDER_SUCCESS,
  CREATE_GROUP_ORDER_FAILURE,
  SHOW_EDIT_GROUP_ORDER_FORM,
  HIDE_EDIT_GROUP_ORDER_FORM,
  EDIT_GROUP_ORDER_REQUEST,
  EDIT_GROUP_ORDER_SUCCESS,
  EDIT_GROUP_ORDER_FAILURE,
  SHOW_CREATE_PAYMENT_METHOD_FORM,
  HIDE_CREATE_PAYMENT_METHOD_FORM,
  CREATE_PAYMENT_METHOD_REQUEST,
  CREATE_PAYMENT_METHOD_SUCCESS,
  CREATE_PAYMENT_METHOD_FAILURE,
  SHOW_CREATE_DELIVERY_ADDRESS_FORM,
  HIDE_CREATE_DELIVERY_ADDRESS_FORM,
  CREATE_DELIVERY_ADDRESS_REQUEST,
  CREATE_DELIVERY_ADDRESS_SUCCESS,
  CREATE_DELIVERY_ADDRESS_FAILURE,
  CREATE_ALERT,
  CLEAR_ALERT,
  EXPORT_GROUP_ORDER_REQUEST,
  EXPORT_GROUP_ORDER_SUCCESS,
  EXPORT_GROUP_ORDER_FAILURE
} from './types';
import randomId from 'random-id';

export default function(
  state = {
    pageToShow: 'login',
    groupOrders: false,
    // paymentMethods: false,
    deliveryAddresses: false,
    createGroupOrderFormIsVisible: false,
    cancelGroupOrderFormIsVisible: false,
    alert: false
  },
  action
) {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        requestingLogin: true
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        requestingLogin: false
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        requestingLogin: false
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods
      };
    case CHANGE_PAGE:
      return {
        ...state,
        pageToShow: action.pageToShow
      };
    case GET_GROUP_ORDERS_SUCCESS:
      return {
        ...state,
        groupOrders: action.groupOrders
      };
    case GET_GROUP_ORDER_DETAILS_REQUEST:
      return {
        ...state,
        requestingGetGroupOrderDetails: true
      };
    case GET_GROUP_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        requestingGetGroupOrderDetails: false,
        groupOrderDetails: action.groupOrderDetails
      };
    case CLEAR_GROUP_ORDER_DETAILS:
      return {
        ...state,
        groupOrderDetails: null
      };
    case SHOW_CANCEL_GROUP_ORDER_FORM:
      return {
        ...state,
        cancelGroupOrderFormIsVisible: true,
        groupOrderIdToCancel: action.groupOrderIdToCancel
      };
    case HIDE_CANCEL_GROUP_ORDER_FORM:
      return {
        ...state,
        cancelGroupOrderFormIsVisible: false,
        groupOrderIdToCancel: false
      };
    case CANCEL_GROUP_ORDER_REQUEST:
      return {
        ...state,
        requestingCancelGroupOrder: true
      };
    case CANCEL_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        requestingCancelGroupOrder: false,
        cancelGroupOrderFormIsVisible: false,
        groupOrderIdToCancel: false,
        groupOrders: state.groupOrders.filter(
          ({id}) => id != state.groupOrderIdToCancel
        )
      };
    case CANCEL_GROUP_ORDER_FAILURE:
      return {
        ...state,
        requestingCancelGroupOrder: false
      };
    case SHOW_CANCEL_SINGLE_ORDER_FORM:
      return {
        ...state,
        cancelSingleOrderFormIsVisible: true,
        singleOrderIdToCancel: action.singleOrderIdToCancel
      };
    case HIDE_CANCEL_SINGLE_ORDER_FORM:
      return {
        ...state,
        cancelSingleOrderFormIsVisible: false,
        singleOrderIdToCancel: false
      };
    case CANCEL_SINGLE_ORDER_REQUEST:
      return {
        ...state,
        requestingCancelSingleOrder: true
      };
    case CANCEL_SINGLE_ORDER_SUCCESS:
      return {
        ...state,
        requestingCancelSingleOrder: false,
        cancelSingleOrderFormIsVisible: false,
        singleOrderIdToCancel: false,
        groupOrderDetails: {
          ...state.groupOrderDetails,
          orders: state.groupOrderDetails.orders.filter(
            ({order_id}) => order_id !== action.singleOrderId
          )
        }
      };
    case CANCEL_SINGLE_ORDER_FAILURE:
      return {
        ...state,
        requestingCancelSingleOrder: false
      };
    case GET_DELIVERY_ADDRESSES_REQUEST:
      return {
        ...state,
        requestingGetDeliveryAddresses: true
      };
    case GET_DELIVERY_ADDRESSES_SUCCESS:
      return {
        ...state,
        requestingGetDeliveryAddresses: false,
        deliveryAddresses: action.deliveryAddresses
      };
    case GET_DELIVERY_ADDRESSES_FAILURE:
      return {
        ...state,
        requestingGetDeliveryAddresses: false,
        error: action.error
      };
    case SHOW_CREATE_GROUP_ORDER_FORM:
      return {
        ...state,
        createGroupOrderFormIsVisible: true
      };
    case HIDE_CREATE_GROUP_ORDER_FORM:
      return {
        ...state,
        createGroupOrderFormIsVisible: false
      };
    case CREATE_GROUP_ORDER_REQUEST:
      return {
        ...state,
        requestingCreateGroupOrder: true
      };
    case CREATE_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        requestingCreateGroupOrder: false,
        createGroupOrderFormIsVisible: false
      };
    case CREATE_GROUP_ORDER_FAILURE:
      return {
        ...state,
        requestingCreateGroupOrder: false
      };
    case SHOW_EDIT_GROUP_ORDER_FORM:
      return {
        ...state,
        editGroupOrderFormIsVisible: true,
        editParams: action.params
      };
    case HIDE_EDIT_GROUP_ORDER_FORM:
      return {
        ...state,
        editGroupOrderFormIsVisible: false,
        editParams: null
      };
    case EDIT_GROUP_ORDER_REQUEST:
      return {
        ...state,
        requestingEditGroupOrder: true
      };
    case EDIT_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        requestingEditGroupOrder: false,
        editGroupOrderFormIsVisible: false
      };
    case EDIT_GROUP_ORDER_FAILURE:
      return {
        ...state,
        requestingEditGroupOrder: false
      };
    case SHOW_CREATE_PAYMENT_METHOD_FORM:
      return {
        ...state,
        createPaymentMethodFormIsVisible: true,
        createGroupOrderFormIsVisible: false
      };
    case HIDE_CREATE_PAYMENT_METHOD_FORM:
      return {
        ...state,
        createPaymentMethodFormIsVisible: false,
        createGroupOrderFormIsVisible: true
      };
    case CREATE_PAYMENT_METHOD_REQUEST:
      return {
        ...state,
        requestingCreatePaymentMethod: true
      };
    case CREATE_PAYMENT_METHOD_SUCCESS:
      const newPaymentMethod = action.paymentMethods[0];
      return {
        ...state,
        requestingCreatePaymentMethod: false,
        createPaymentMethodFormIsVisible: false,
        createGroupOrderFormIsVisible: true,
        paymentMethods: [
          {
            card_id: newPaymentMethod.id,
            last_4: newPaymentMethod.details.last_four,
            type: newPaymentMethod.details.card_type,
            expiry: newPaymentMethod.details.expiry_date
          },
          ...state.paymentMethods
        ]
      };
    case CREATE_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        requestingCreatePaymentMethod: false
      };
    case SHOW_CREATE_DELIVERY_ADDRESS_FORM:
      return {
        ...state,
        createDeliveryAddressFormIsVisible: true,
        createGroupOrderFormIsVisible: false
      };
    case HIDE_CREATE_DELIVERY_ADDRESS_FORM:
      return {
        ...state,
        createDeliveryAddressFormIsVisible: false,
        createGroupOrderFormIsVisible: true
      };
    case CREATE_DELIVERY_ADDRESS_REQUEST:
      return {
        ...state,
        requestingCreateDeliveryAddress: true
      };
    case CREATE_DELIVERY_ADDRESS_SUCCESS:
      return {
        ...state,
        requestingCreateDeliveryAddress: false,
        createDeliveryAddressFormIsVisible: false,
        createGroupOrderFormIsVisible: true,
        deliveryAddresses: [action.deliveryAddress, ...state.deliveryAddresses]
      };
    case CREATE_DELIVERY_ADDRESS_FAILURE:
      return {
        ...state,
        requestingCreateDeliveryAddress: false
      };
    case CREATE_ALERT:
      return {
        ...state,
        alert: {
          id: randomId(10),
          ...action.alert
        }
      };
    case CLEAR_ALERT:
      return {
        ...state,
        alert: false
      };
    case EXPORT_GROUP_ORDER_REQUEST:
      return {
        ...state,
        requestingExportGroupOrder: true,
        groupOrderIdRequestingExport: action.groupOrderIdRequestingExport
      };
    case EXPORT_GROUP_ORDER_SUCCESS:
      return {
        ...state,
        requestingExportGroupOrder: false,
        groupOrderIdRequestingExport: false
      };
    case EXPORT_GROUP_ORDER_FAILURE:
      return {
        ...state,
        requestingExportGroupOrder: false,
        groupOrderIdRequestingExport: false
      };
    default:
      return state;
  }
}
