import React, {Fragment} from 'react';
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button, {ButtonRow} from '../../../components/Button';

const GroupOrdersTable = ({
  groupOrders,
  showCancelGroupOrderForm,
  getGroupOrderDetails,
  exportGroupOrder,
  groupOrderIdRequestingExport,
  past
}) => {
  const renderActions = groupOrder => {
    if (past) {
      return (
        <Fragment>
          <Button onClick={() => getGroupOrderDetails(groupOrder.id)} small>
            View
          </Button>
          {groupOrder.id === groupOrderIdRequestingExport ? (
            <span>Exporting...</span>
          ) : (
            <Button onClick={() => exportGroupOrder(groupOrder.id)} small>
              Export
            </Button>
          )}
        </Fragment>
      );
    }
    return (
      <Fragment>
        <Button onClick={() => getGroupOrderDetails(groupOrder.id)} small>
          View
        </Button>
        <Button onClick={() => showCancelGroupOrderForm(groupOrder.id)} small>
          Cancel
        </Button>
      </Fragment>
    );
  };

  const renderNoOrdersMessage = () => (
    <div style={{fontSize: '12px'}}>
      There are no {`${past ? 'past' : 'current'}`} group orders to display.
    </div>
  );

  return (
    <div style={{overflowX: 'auto'}}>
      {groupOrders.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">Group Order Code</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Participants</TableCell>
              <TableCell align="center">Delivery Date/Time</TableCell>
              <TableCell align="center">Deadline Time</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groupOrders &&
              groupOrders.map(groupOrder => {
                return (
                  <TableRow key={groupOrder.order_group_code}>
                    <TableCell component="th" scope="row">
                      {groupOrder.order_group_code}
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{textTransform: 'capitalize'}}
                    >
                      {groupOrder.status}
                    </TableCell>
                    <TableCell align="center">
                      {groupOrder.current_group_size} /{' '}
                      {groupOrder.total_group_size}
                    </TableCell>
                    <TableCell align="center">
                      {moment(groupOrder.delivery_date).format('MMM DD h:mm A')}
                    </TableCell>
                    <TableCell align="center">
                      {moment(
                        `${groupOrder.date_limit} ${groupOrder.time_limit}`
                      ).format('h:mm A')}
                    </TableCell>
                    <TableCell align="center">
                      <ButtonRow justifyContent="space-around">
                        {renderActions(groupOrder)}
                      </ButtonRow>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      ) : (
        <Fragment>{renderNoOrdersMessage()}</Fragment>
      )}
    </div>
  );
};

export default GroupOrdersTable;
