import React from 'react';
import styled from 'styled-components';
import Header from './components/Header';

const Wrapper = styled.div``;

const AppBody = styled.div`
  margin: 110px 80px 60px 80px;
  @media (max-width: 600px) {
    margin: 130px 0 20px 0;
  }
`;

const Layout = ({children, handleLogout}) => (
  <Wrapper>
    <Header handleLogout={handleLogout} />
    <AppBody>{children}</AppBody>
  </Wrapper>
);

export default Layout;
