import styled from 'styled-components';

const Button = styled.button`
  min-width: ${props => (props.small ? '80' : '160')}px;
  height: ${props => (props.small ? 'inherit' : '45px')};
  border-radius: 5px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.12);
  background-color: ${props =>
    props.secondary ? 'white' : props.danger ? 'red' : '#006296'};
  color: ${props => (props.secondary ? '#006296' : 'white')};
  border: 2px solid ${props => (props.danger ? 'red' : '#006296')};
  cursor: pointer;
`;

export const ButtonRow = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: center;
  @media (max-width: 600px) {
    grid-auto-flow: row;
    justify-content: stretch;
  }
`;

export default Button;
