import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Button, {ButtonRow} from '../../../components/Button';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 48px;
`;

const DeleteConfirmation = ({
  groupOrderIdToCancel,
  cancelGroupOrderFormIsVisible,
  hideCancelGroupOrderForm,
  cancelGroupOrder
}) => {
  return (
    <Modal
      closeModal={hideCancelGroupOrderForm}
      open={cancelGroupOrderFormIsVisible}
    >
      <Wrapper>
        <div>Are you sure you want to cancel this group order?</div>
        <ButtonRow>
          <Button onClick={hideCancelGroupOrderForm} type="button" secondary>
            No
          </Button>
          <Button onClick={() => cancelGroupOrder(groupOrderIdToCancel)}>
            Yes, Cancel
          </Button>
        </ButtonRow>
      </Wrapper>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    cancelGroupOrderFormIsVisible: state.cancelGroupOrderFormIsVisible,
    groupOrderIdToCancel: state.groupOrderIdToCancel
  };
}

export default connect(
  mapStateToProps,
  actions
)(DeleteConfirmation);
