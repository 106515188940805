import axios from 'axios';

export const oldYork = axios.create({
  baseURL: 'https://api.smoothpay.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export const newYork = axios.create({
  baseURL: 'https://api.smoothpay.com/consumer/v2',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

newYork.interceptors.request.use(request => {
  request.headers['X-SP-API-Token'] = localStorage.getItem('cfgomtoken');
  return request;
});
