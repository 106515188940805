import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from './redux/actions';
import Layout from './containers/Layout/Layout';
import Login from './containers/Login/Login';
import Home from './containers/Home/Home';
import Detail from './containers/Detail/Detail';

class App extends Component {
  componentDidMount() {
    if (localStorage.cfgomtoken) {
      this.props.changePage('home');
      this.props.getGroupOrders();
      this.props.getDeliveryAddresses();
      setInterval(() => {
        if (localStorage.cfgomtoken) {
          this.props.getGroupOrders();
        }
      }, 10000);
    }
    if (localStorage.cfgompaymentMethods) {
      this.props.setPaymentMethods(
        JSON.parse(localStorage.cfgompaymentMethods)
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pageToShow === 'login' && this.props.pageToShow === 'home') {
      this.props.getGroupOrders();
      this.props.getDeliveryAddresses();
      setInterval(() => {
        if (localStorage.cfgomtoken) {
          this.props.getGroupOrders();
        }
      }, 10000);
    }
    if (
      prevProps.createGroupOrderFormIsVisible &&
      prevProps.requestingCreateGroupOrder &&
      !this.props.createGroupOrderFormIsVisible &&
      !this.props.requestingCreateGroupOrder
    ) {
      this.props.getGroupOrders();
    }
  }

  renderPage = () => {
    switch (this.props.pageToShow) {
      case 'login':
        return <Login />;
      case 'home':
        return <Home paymentMethods={[]} />;
      case 'details':
        return <Detail />;
    }
  };

  render() {
    return (
      <Layout handleLogout={this.handleLogout}>{this.renderPage()}</Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    pageToShow: state.pageToShow,
    requestingCreateGroupOrder: state.requestingCreateGroupOrder,
    createGroupOrderFormIsVisible: state.createGroupOrderFormIsVisible
  };
}

export default connect(
  mapStateToProps,
  actions
)(App);
