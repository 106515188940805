import React, {Component} from 'react';
import {connect} from 'react-redux';
import {login} from '../../redux/actions';
import styled from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Card from '../../components/Card';
import Title from '../../components/Title';
import LoginForm from './components/LoginForm';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

const LoginCard = styled(Card)`
  width: 420px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

class Login extends Component {
  render() {
    return (
      <div>
        <PageWrapper pageTitle="Welcome to Group Ordering" />
        <LoginCard>
          <Wrapper>
            <Title>Use your CF EATS login to get started</Title>
            <div>
              A Group Order allows a group of people to order from their own
              device and join the same order and delivery time at checkout.
            </div>
            <LoginForm {...this.props} />
          </Wrapper>
        </LoginCard>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    requestingLogin: state.requestingLogin
  };
}

export default connect(
  mapStateToProps,
  {login}
)(Login);
