import styled from 'styled-components';

const PageTitle = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #223a5e;
  @media (max-width: 600px) {
    padding: 0 16px;
  }
`;

export default PageTitle;
