import React, {Component} from 'react';
import * as actions from '../../../redux/actions';
import {connect} from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import Modal from '../../../components/Modal';
import Title from '../../../components/Title';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Button, {ButtonRow} from '../../../components/Button';

const FormWrapper = styled.div`
  display: grid;
  grid-gap: 40px;
`;

const todayDateString = moment().format('YYYY-MM-DD');

const SelectAddWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 175px;
  grid-gap: 24px;
  align-items: flex-end;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const initialState = {
  date_limit: todayDateString,
  time_limit: '12:00',
  group_size: '',
  customer_address_id: '',
  payment_method_id: ''
};

class CreateGroupOrder extends Component {
  state = initialState;

  componentDidUpdate(prevProps) {
    if (
      prevProps.createGroupOrderFormIsVisible &&
      !this.props.createGroupOrderFormIsVisible
    ) {
      this.setState(initialState);
    }
    if (
      !prevProps.createGroupOrderFormIsVisible &&
      this.props.createGroupOrderFormIsVisible
    ) {
      if (this.props.paymentMethods && this.props.paymentMethods.length) {
        this.setState({
          payment_method_id: this.props.paymentMethods[0].card_id
        });
      }
      if (this.props.deliveryAddresses.length) {
        this.setState({
          customer_address_id: this.props.deliveryAddresses[0].id
        });
      }
    }
  }

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const formValues = {
      ...this.state,
      time_limit: `${this.state.time_limit}:00`,
      group_size: parseInt(this.state.group_size),
      customer_address_id: parseInt(this.state.customer_address_id),
      payment_method_id: parseInt(this.state.payment_method_id)
    };
    this.props.createGroupOrder(formValues);
  };

  render() {
    const {
      date_limit,
      time_limit,
      group_size,
      customer_address_id,
      payment_method_id
    } = this.state;
    const {
      deliveryAddresses,
      paymentMethods,
      createGroupOrderFormIsVisible,
      hideCreateGroupOrderForm,
      showCreatePaymentMethodForm,
      showCreateDeliveryAddressForm
    } = this.props;
    return (
      <Modal
        closeModal={hideCreateGroupOrderForm}
        open={createGroupOrderFormIsVisible}
      >
        <Title style={{marginBottom: '20px'}}>Create Group Order</Title>
        {createGroupOrderFormIsVisible && (
          <form onSubmit={this.handleSubmit}>
            <FormWrapper>
              <TextField
                label="Delivery Date"
                value={date_limit}
                type="date"
                InputLabelProps={{shrink: true}}
                inputProps={{min: todayDateString}}
                onChange={this.handleChange('date_limit')}
                required
              />
              <TextField
                label="Delivery Time"
                type="time"
                InputLabelProps={{shrink: true}}
                inputProps={{
                  min: `${
                    date_limit === todayDateString
                      ? moment()
                          .add(2, 'hours')
                          .format('HH:mm')
                      : '00:00'
                  }`
                }}
                value={time_limit}
                onChange={this.handleChange('time_limit')}
                required
              />
              <TextField
                label="Group Size"
                type="number"
                value={group_size}
                onChange={this.handleChange('group_size')}
                inputProps={{min: 2}}
                required
              />
              <SelectAddWrapper>
                <FormControl required>
                  <InputLabel htmlFor="deliveryAddressSelect">
                    Payment Method
                  </InputLabel>
                  <Select
                    native
                    value={payment_method_id}
                    onChange={this.handleChange('payment_method_id')}
                    inputProps={{
                      name: 'payment_method_id',
                      id: 'paymentMethodSelect'
                    }}
                  >
                    <option value="" />
                    {paymentMethods &&
                      paymentMethods.length &&
                      paymentMethods.map(paymentMethod => (
                        <option
                          key={paymentMethod.card_id}
                          value={paymentMethod.card_id}
                        >{`${paymentMethod.type.toUpperCase()} *${
                          paymentMethod.last_4
                        }`}</option>
                      ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={showCreatePaymentMethodForm}
                  type="button"
                  small
                >
                  Add Payment Method
                </Button>
              </SelectAddWrapper>
              <SelectAddWrapper>
                <FormControl required>
                  <InputLabel htmlFor="deliveryAddressSelect">
                    Delivery Address
                  </InputLabel>
                  <Select
                    native
                    value={customer_address_id}
                    onChange={this.handleChange('customer_address_id')}
                    inputProps={{
                      name: 'customer_address_id',
                      id: 'deliveryAddressSelect'
                    }}
                  >
                    <option value="" />
                    {deliveryAddresses.length &&
                      deliveryAddresses.map(address => (
                        <option key={address.id} value={address.id}>
                          {address.line1}
                        </option>
                      ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={showCreateDeliveryAddressForm}
                  type="button"
                  small
                >
                  Add Address
                </Button>
              </SelectAddWrapper>
              <ButtonRow justifyContent="space-between ">
                <Button
                  onClick={hideCreateGroupOrderForm}
                  type="button"
                  secondary
                >
                  Cancel
                </Button>
                <Button>Create Group</Button>
              </ButtonRow>
            </FormWrapper>
          </form>
        )}
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    deliveryAddresses: state.deliveryAddresses,
    createGroupOrderFormIsVisible: state.createGroupOrderFormIsVisible,
    paymentMethods: state.paymentMethods
  };
}

export default connect(
  mapStateToProps,
  actions
)(CreateGroupOrder);
