import React from 'react';
import {connect} from 'react-redux';
import {logout} from '../../../redux/actions';
import styled from 'styled-components';
import FlatButton from '../../../components/FlatButton';
import Logo from '../../../assets/cf_eats_logo.png';

const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 60px;
  padding: 10px;
  background-color: #b8dae5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  z-index: 99;
  @media (max-width: 600px) {
    height: 100px;
  }
`;

const LogoWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: flex-end;
  grid-gap: 4px;
  @media (max-width: 600px) {
    grid-auto-flow: row;
  }
`;

const LogoImg = styled.img`
  max-width: 100%;
  height: 30px;
`;

const Header = ({logout, pageToShow}) => {
  return (
    <Wrapper>
      <LogoWrapper>
        <LogoImg src={Logo} />
        <div>Group Ordering</div>
      </LogoWrapper>
      {pageToShow !== 'login' && (
        <FlatButton onClick={logout}>Sign Out</FlatButton>
      )}
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    pageToShow: state.pageToShow
  };
}

export default connect(
  mapStateToProps,
  {logout}
)(Header);
