import React from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../redux/actions';
import styled from 'styled-components';
import Modal from '../../../components/Modal';
import Button, {ButtonRow} from '../../../components/Button';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 48px;
`;

const DeleteConfirmation = ({
  groupOrderId,
  singleOrderIdToCancel,
  cancelSingleOrderFormIsVisible,
  hideCancelSingleOrderForm,
  cancelSingleOrder
}) => {
  return (
    <Modal
      closeModal={hideCancelSingleOrderForm}
      open={cancelSingleOrderFormIsVisible}
    >
      <Wrapper>
        <div>Are you sure you want to cancel this order?</div>
        <ButtonRow>
          <Button onClick={hideCancelSingleOrderForm} type="button" secondary>
            No
          </Button>
          <Button
            onClick={() =>
              cancelSingleOrder({groupOrderId, singleOrderIdToCancel})
            }
          >
            Yes, Cancel
          </Button>
        </ButtonRow>
      </Wrapper>
    </Modal>
  );
};

function mapStateToProps(state) {
  return {
    cancelSingleOrderFormIsVisible: state.cancelSingleOrderFormIsVisible,
    singleOrderIdToCancel: state.singleOrderIdToCancel,
    groupOrderId: state.groupOrderDetails && state.groupOrderDetails.id
  };
}

export default connect(
  mapStateToProps,
  actions
)(DeleteConfirmation);
