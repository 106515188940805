import React, {Component} from 'react';
import {connect} from 'react-redux';
import * as actions from '../../redux/actions';
import styled from 'styled-components';
import PageWrapper from '../../components/PageWrapper';
import Card from '../../components/Card';
import Title from '../../components/Title';
import Button, {ButtonRow} from '../../components/Button';
import GroupOrdersTable from './components/GroupOrdersTable';
import DeleteConfirmation from './components/DeleteConfirmation';
import CreateGroupOrder from './components/CreateGroupOrder';
import CreatePaymentMethod from './components/CreatePaymentMethod';
import CreateDeliveryAddress from './components/CreateDeliveryAddress';

const Wrapper = styled.div`
  display: grid;
  grid-gap: 24px;
`;

class Home extends Component {
  activeOrders = groupOrders =>
    groupOrders.filter(({status}) => status !== 'delivered');

  pastOrders = groupOrders =>
    groupOrders.filter(({status}) => status === 'delivered');

  render() {
    const {
      groupOrders,
      paymentMethods,
      showCreateGroupOrderForm,
      showCancelGroupOrderForm,
      getGroupOrderDetails,
      exportGroupOrder,
      groupOrderIdRequestingExport
    } = this.props;
    return (
      <div>
        <PageWrapper pageTitle="Group Orders" />
        <ButtonRow justifyContent="flex-end" style={{marginBottom: '20px'}}>
          <Button onClick={showCreateGroupOrderForm}>Create Group Order</Button>
        </ButtonRow>
        <Card>
          <Wrapper>
            <Title>Active Group Orders</Title>
            {groupOrders.length > 0 && (
              <GroupOrdersTable
                groupOrders={this.activeOrders(groupOrders)}
                showCancelGroupOrderForm={showCancelGroupOrderForm}
                getGroupOrderDetails={getGroupOrderDetails}
              />
            )}
            <br />
            <br />
            <Title>Past Group Orders</Title>
            {groupOrders.length > 0 && (
              <GroupOrdersTable
                groupOrders={this.pastOrders(groupOrders)}
                getGroupOrderDetails={getGroupOrderDetails}
                exportGroupOrder={exportGroupOrder}
                groupOrderIdRequestingExport={groupOrderIdRequestingExport}
                past
              />
            )}
          </Wrapper>
        </Card>
        <CreateGroupOrder paymentMethods={paymentMethods} />
        <CreatePaymentMethod />
        <CreateDeliveryAddress />
        <DeleteConfirmation />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    groupOrders: state.groupOrders,
    groupOrderIdRequestingExport: state.groupOrderIdRequestingExport
  };
}

export default connect(
  mapStateToProps,
  actions
)(Home);
